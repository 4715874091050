import React from "react";

//api
import {ukApiHelper} from "../helper/apiHelper";

import {serverSideTranslations} from "next-i18next/serverSideTranslations";

import AppPage from "../components/AppPage";

export default function App(props) {

    return (
        <>
            <AppPage {...props} />
        </>
    );
}

export async function getStaticProps(context) {
    const DEFAULT_META = process.env.defaultMeta;

    const locale = context.locale;

    const subMenuData = await ukApiHelper(`subMenu`, 'GET', null, null, locale);
    const res = await ukApiHelper(`seoV1?page=price&is_live=${process.env.isApiLive}`, 'GET', null, null, locale);

    const sub_menu_data = subMenuData.data.status ? subMenuData.data.data : null;

    const meta = res.data.status ? res.data.data : DEFAULT_META;

    const resHrefLang = await ukApiHelper('hreflang?page=price', 'GET', null, null, locale);
    const hrefLang = resHrefLang.data.status ? resHrefLang.data.data : null;

    return {
        props: {
            meta,
            sub_menu_data,
            currLocale: locale,
            hrefLang,
            ...(await serverSideTranslations(locale, ['common']))
        },
        revalidate: 10
    }
}
