import React from "react";
import Image from 'next/image';
// dynamic
import dynamic from 'next/dynamic';
//img
import downloadApp from "../../public/download-the-app-today.png";
import benefitsOnyourDeviceImg from "../../public/benefit-on-your-devive.png";
import lightningFast from "../../public/lightning-fast.svg";
import assistance from "../../public/assistance.svg";
import professionals from "../../public/professionals.svg";
import interface1 from "../../public/interface-1.png";
import interface2 from "../../public/interface-2.png";
import interface3 from "../../public/interface-3.png";
import interface4 from "../../public/interface-4.png";
import Img from "../Common/image";
import downloadImg from "../../public/downloadAppImg.png"
// react slick
import Slider from "react-slick";
import slickCss from "slick-carousel/slick/slick.css";
//Rating
import Rating from 'react-rating';
// scss
import styles from "./App.module.scss";
import {reviewData} from "./data";
//component
const Meta = dynamic(() => import('../meta/meta'));


const AppPage = (props) => {
    const { meta, currLocale } = props;
    const { title, description, keywords, url_group } = meta;
    const interfaceSlider = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    const testimonialSlider = {
        speed: 500,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    }
    return (
        <>
            {/*<style dangerouslySetInnerHTML={{ __html: AppPageCss }} />*/}
            <style dangerouslySetInnerHTML={{ __html: slickCss }} />
            <Meta title={title}
                description={description}
                keywords={keywords}
                urlCategory={url_group}
                currLocale={currLocale} />
            <div className={styles.topNavbar}>
                <header>
                    <div className="container">
                        <div className="row">
                            <div className={`col-md-6 ${styles.download_hero}`}>
                                <h1 className="title">A reliable essay writing app for students</h1>
                                <p className="desc mb-5">Use our essay writing app to get professional help with your essay assignments. Tell us your essay requirements, and get your job done by our professional writers within the deadline at an affordable price.
                                </p>
                                <div className={styles.download}>
                                    <ul className="d-flex align-items-center">
                                        <li>
                                            <a className="app-link"
                                               href="https://apps.apple.com/us/app/cheapest-custom-writing-papers/id1447217562"
                                               target="_blank"
                                               rel="noreferrer">
                                                <Image src="/app-store.webp"
                                                       alt="app store"
                                                       width={146} height={45}
                                                       title="Appstore" />
                                            </a>
                                        </li>
                                        <li className="ms-3">
                                            <a className="app-link"
                                               href="https://play.google.com/store/apps/details?id=com.cheapestessay.service" target="_blank" rel="noreferrer">
                                                <Image src="/google-play.webp"
                                                       alt="google play"
                                                       width={146} height={45}
                                                       title="Google Play" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="d-flex mt-5">
                                    <div className={`${styles.getstarted} ps-0`}>
                                        <span>20K+</span>
                                        <span>Downloads</span>
                                    </div>
                                    <div className={styles.getstarted}>
                                        <span>490+</span>
                                        <span>Happy Clients</span>
                                    </div>
                                    <div className={styles.getstarted}>
                                        <span>4.9</span>
                                        <span>Ratings</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className={`text-center ${styles.downloadAppToday}`}>
                                    <Image src={downloadApp}
                                           alt="Download the App Today"
                                           width={357} height={404} />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
            <section className={`pt-0 ${styles.benefitsDevice}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className={styles.benefits_section}>
                                <Image src={benefitsOnyourDeviceImg}
                                       alt="benefits On your Device"
                                       width={481} height={483} />
                            </div>
                        </div>
                        <div className="col-md-6">

                            <h2 className="section-title">All CheapestEssay benefits on your device</h2>
                            <ul className={styles.benefits}>
                                <li>
                                    <Image src={lightningFast} alt="Lightning-fast help with your essay deadlines" width={50} height={50} />
                                    <span>Lightning-fast help with your essay deadlines</span>
                                </li>
                                <li>
                                    <Image src={assistance} alt="24/7 assistance with papers of any difficulty level" width={50} height={50} />
                                    <span>24/7 assistance with papers of any difficulty level</span>
                                </li>
                                <li>
                                    <Image src={professionals} alt="Over 500 professionals in all disciplines" width={50} height={50} />
                                    <span>Over 500 professionals in all disciplines</span>
                                </li>
                                <li>
                                    <Image src={professionals} alt="Original content with zero plagiarism" width={50} height={50} />
                                    <span>Original content with zero plagiarism</span>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>
            <section className={`${styles.interface} pt-0`}>
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-title">Simple & Beautiful Interface</h2>
                    </div>
                    <Slider {...interfaceSlider}
                            className={styles.slickSlider}>
                        <div className={styles.slickSlide}>
                            <Image src={interface1} alt="interface-1"
                                   width={268} height={530} />
                        </div>
                        <div className={styles.slickSlide}>
                            <Image src={interface2} alt="interface-2"
                                   width={268} height={530} />
                        </div>
                        <div className={styles.slickSlide}>
                            <Image src={interface3} alt="interface-3"
                                   width={268} height={530} />
                        </div>
                        <div className={styles.slickSlide}>
                            <Image src={interface4} alt="interface-4"
                                   width={268} height={530} />
                        </div>
                        <div className={styles.slickSlide}>
                            <Image src={interface4} alt="interface-4"
                                   width={268} height={530} />
                        </div>
                    </Slider>
                </div>
            </section>
              <section className={`${styles.testimonial} p-0`}>
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-title">
                            See what our customers have to say
                        </h2>
                    </div>
                    <Slider {...testimonialSlider}>
                        {reviewData.map(item => (
                            <div className={styles.slickSlide} key={item.id}>
                                <div className={`${styles.feedbackDetail}`}>
                                    <div className="info">
                                        <div className={styles.rating}>
                                            <Rating
                                                readonly={true}
                                                initialRating={5}
                                                fractions={2}
                                                emptySymbol={<Img src="/empty.svg" title="Empty" className="icon" alt="ratingImg" width={18} height={18} />}
                                                fullSymbol={<Img src="/full.svg" title="Full" className="icon" alt="ratingImg" width={18} height={18} />}
                                            />
                                        </div>
                                        <div className={styles.reviewTitle}>
                                            <strong style={{ 'fontWeight': '500' }}>
                                                {item.reviewTitle}
                                            </strong>
                                        </div>
                                        <p className="content">{item.reviewContent}</p>
                                    </div>
                                    <div className={styles.customerDetail}>
                                        <div className={styles.name}>{item.customerName}</div>
                                        <span className={styles.id}>{item.reviewDate}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
             <section className={`${styles.downloadApp} position-relative`}>
                <div className="container">
                    <div className={styles.downloadAppBg}>
                        <Image src={downloadImg}
                               alt="Download the App Today and Get Started!"
                               width={347} height={441} />
                    </div>
                    <div className="row">
                        <div className="col-md-6 offset-md-6">
                            <h2 className="section-title text-white">Download the App Today and Get Started!</h2>
                            <p className="desc text-white w-100">Now get full control over your academics with our out-of-the-box essay writing service.</p>
                            <div className={styles.download}>
                                <ul className="d-flex align-items-center">
                                    <li>
                                        <a className="app-link" href="https://apps.apple.com/us/app/cheapest-custom-writing-papers/id1447217562" target="_blank" rel="noreferrer">
                                            <Image src="/app-store.webp" alt="app store" width={146} height={45} title="Appstore" />
                                        </a>
                                    </li>
                                    <li className="ms-3">
                                        <a className="app-link" href="https://play.google.com/store/apps/details?id=com.cheapestessay.service" target="_blank" rel="noreferrer">
                                            <Image src="/google-play.webp" alt="google play" width={146} height={45} title="Google Play" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
             {/* <div className="combined-content-wrapper">
                <Content />
                <Content bottomContent={true} />
            </div>
            <Faq />
            <RecentArticles />
            <Contact currLocale={currLocale && currLocale} />  */}
        </>
    );
}

export default AppPage;