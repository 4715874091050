export const reviewData = [
    {
        id: 1,
        reviewTitle: "Great app to write essays",
        reviewContent: "Ordered a history essay and got it on time. The essay was perfect, and the writer followed all my instructions",
        customerName: "Kate Evans",
        reviewDate: "Feb 8, 2023"
    },
    {
        id: 2,
        reviewTitle: "Very quick essay writing service",
        reviewContent: "I used the app for the first time when the deadline was close and got my essay within a day! Excellent job by the team.",
        customerName: "Mary Winslet",
        reviewDate: "Jun 15, 2023"
    },
    {
        id: 3,
        reviewTitle: "Thumbs up to the support team",
        reviewContent: "A great customer support team clarified my doubts immediately and helped me place my first order. Thank you, guys!",
        customerName: "Edward Johnson",
        reviewDate: "Mar 13, 2023"
    },
    {
        id: 4,
        reviewTitle: "Good job by the writers",
        reviewContent: "Selected a writer to do my literature essay. The essay was just wow! Got an ‘A’ from my professor. Just love the app.",
        customerName: "Mia Miller",
        reviewDate: "Feb 19, 2023"
    },
    {
        id: 5,
        reviewTitle: "The best for essay assignments",
        reviewContent: "They eased my essay writing assignments to a great extent. This is my 3rd order, and I am really happy with the service.",
        customerName: "Joe Williams",
        reviewDate: "Apr 25, 2023"
    },
    {
        id: 6,
        reviewTitle: "A very user-friendly app",
        reviewContent: "The app is very easy to use. Ordered my essays without any hassles and received them on time. Good work.",
        customerName: "Jasmine Hart",
        reviewDate: "Apr 10, 2023"
    },
    {
        id: 7,
        reviewTitle: "This app is really a savior",
        reviewContent: "I don’t have to worry about my essay homework anymore! This app saves time and effort if you are looking for essay writing help.",
        customerName: "Maya Smith",
        reviewDate: "Mar 20, 2023"
    },
    {
        id: 8,
        reviewTitle: "Terrific job by the team",
        reviewContent: "The paper was written quickly, and it was to my liking. The writer has a high level of academic ability and experience. I am very satisfied.",
        customerName: "Angelina Davis",
        reviewDate: "Apr 30, 2023"
    },
    {
        id: 9,
        reviewTitle: "Affordable essay writing service",
        reviewContent: "The service is really affordable for students, and the writers know their craft well. I will definitely order again.",
        customerName: "Samuel Brown",
        reviewDate: "Apr 1, 2023"
    }
]